<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      light
      class="pb-5"
      color="#333333"
      width="1300"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Результаты ФЛК </span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="white pa-5 rounded-0">
        <p class="error-message">
          Выявлены ошибки при проведении контроля!
        </p>
        <div class="d-flex justify-end">
          <v-btn
            text
            class="mr-2"
            @click="onSelect"
          >
            Отправить в ИСТО
          </v-btn>
          <v-btn
            color="green"
            class="white--text elevation-0"
            @click="close"
          >
            Исправить ошибки
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
import {waitAsyncDecision} from "@/helpers/async-component";
export default {
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    params:{
      required:true,
      type:Object
    }
  },
  data:() => ({
    asyncDecision:{
      resolve:null,
      reject:null,
      t:null
    }
  }),
  methods:{
    waitAsyncDecision,
    onSelect(){
      this.asyncDecision.resolve(true)
      this.$emit('update:show', false)
    },
    close(){
      this.asyncDecision.reject(false)
      this.$emit('update:show', false)
    }
  }
}
</script>
<style scoped>
.error-message{
    font-weight: bold;
    font-size: 18px;
}
</style>
